.errortype0 {
  color: rgba(0, 128, 0, 0.61) !important;
  position: absolute;
  left: 0%;

  top: 0em;
  font-family: "sofiapro pro light" !important;
  font-size: 20px !important;
}
.errortype1 {
  color: rgb(255, 144, 144) !important;
  position: absolute;
  left: 3em;
  top: 15.5em;
  font-size: 12px !important;
}
.errortype2 {
  color: rgb(255, 144, 144) !important;
  position: absolute;
  left: 3em;

  top: 22em;

  font-size: 12px !important;
}
.errortype3 {
  color: rgba(255, 255, 255, 0) !important;
  position: absolute;
  right: 4%;

  top: 19.1em;

  font-size: 14px !important;
}

.errortype5 {
  color: rgba(185, 8, 8, 0.61) !important;
  position: absolute;
  margin-left: 0%;
  top: 1em;
  font-size: 13px !important;
}
.errortype6 {
  color: rgba(185, 8, 8, 0.61) !important;
  position: absolute;
  margin-left: 0%;
  top: 1em;
  font-size: 13px !important;
}
.errortype7 {
  color: rgba(185, 8, 8, 0.61) !important;
  position: absolute;
  margin-left: 0%;
  top: 1em;
  font-size: 13px !important;
}
.errortype8 {
  color: rgba(185, 8, 8, 0.61) !important;
  position: absolute;
  margin-left: 0%;
  top: 1em;
  font-size: 13px !important;
}
.errortype9 {
  color: rgba(185, 8, 8, 0.61) !important;
  position: absolute;
  margin-left: 0%;
  top: 7.1em;
  font-size: 13px !important;
}
.errortype10 {
  color: rgba(185, 8, 8, 0.61) !important;
  position: absolute;
  margin-left: 0%;
  top: 1em;
  font-size: 13px !important;
}
.errortype11 {
  color: rgba(185, 8, 8, 0.61) !important;
  position: absolute;
  margin-left: 0%;
  top: 1em;
  font-size: 13px !important;
}
.errortype12 {
  color: rgba(185, 8, 8, 0.61) !important;
  position: absolute;
  margin-left: 0%;
  top: 1em;
  font-size: 13px !important;
}
.errortype13 {
  color: rgba(185, 8, 8, 0.61) !important;
  position: absolute;
  margin-left: 0%;
  top: 1em;
  font-size: 13px !important;
}
