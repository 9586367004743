@font-face {
  font-family: Autobus-Bold;
  src: url(../static/fonts/AvertaDemoPE-Regular.otf);
}
.bm-burger-button {
  visibility: hidden;
}
.back-button {
  z-index: 99;
  position: sticky !important;
  width: 5em !important;
  margin-top: -2.5em !important;
  margin-left: -110em !important;
}
.bm-burger-bars {
  visibility: hidden;
}

.bm-burger-bars-hover {
  visibility: hidden;
}

.bm-cross-button {
  visibility: hidden;
}

.bm-cross {
  visibility: hidden;
}

.bm-menu-wrap {
  visibility: hidden;
}
.rates-card-total {
  margin-top: 2.4em !important;
}
.rates-card-header {
  width: 100%;
  margin-left: -1em;
  transform: scale(0.9);
  position: absolute;
}
.rates-card {
  margin: auto !important;
  width: 100% !important;
  margin-bottom: 3em !important;
  min-width: 25em !important;
  float: center;
}
.rates-col {
  float: center;
  text-align: center !important;
  margin: auto !important;
  width: 33% !important;
}
.bm-menu {
  visibility: hidden;
}
.bm-menu1 {
  visibility: hidden;
}

.bm-morph-shape {
  visibility: hidden;
}

.bm-item-list {
  visibility: hidden;
}

.bm-item {
  visibility: hidden;
}

.bm-overlay {
  visibility: hidden;
}
.what-we-col-pin {
  animation: coinflip 1.4s ease-out;
  animation-iteration-count: infinite;
  box-shadow: 0 0 1px 2px #ffffff;
  animation-delay: 1.1s;
}
@keyframes coinflip {
  0% {
    transform: translateY(0.2em);
  }

  50% {
    transform: translateY(-1em);
  }

  100% {
    transform: translateY(0.2em);
  }
}
.how-we-col-pin {
  animation: coinpulse 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;

  animation-delay: 1.1s;
}
@keyframes coinpulse {
  0% {
    transform: scale(1, 1);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: scale(1.3, 1.3);
    opacity: 0;
  }
}

.our-future-col-pin {
  animation: coinflip 1.4s ease-out;
  animation-iteration-count: infinite;

  animation-delay: 1.1s;
}
@keyframes coinflip {
  0% {
    transform: translateY(0.2em);
  }

  50% {
    transform: translateY(-1em);
  }

  100% {
    transform: translateY(0.2em);
  }
}
.what-we-col-img {
  width: 25em !important;
  position: absolute;
  margin: auto !important;
  margin-top: 8em !important;
  margin-left: 43% !important;
}
.how-we-col {
  white-space: nowrap;
  margin: auto !important;
  margin-top: -22em !important;
  margin-left: 55% !important;
}
.how-we-col-img {
  width: 27em !important;

  margin-top: 3.3em !important;
  left: 18% !important;
}
.our-future-col {
  white-space: nowrap;
  margin: auto !important;
  margin-top: -4em !important;
  margin-left: 4% !important;
  margin-bottom: 8em !important;
}
.our-future-col-img {
  width: 24em !important;
  position: absolute;
  margin: auto !important;
  margin-top: -3em !important;
  margin-left: 48% !important;
}
.ant-form-explain,
.ant-form-extra {
  clear: both;
  min-height: 0px !important;
  margin-top: -2px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5 !important;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-form-explain,
.ant-form-extra {
  clear: both;

  min-height: 22px;
  margin-top: 2px !important;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;

  line-height: 1.5;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.about-header-transform {
  font-family: Autobus-Bold !important;
}
.ant-descriptions-item-content {
  border: 1px solid lightgrey !important;
}
.ant-input:placeholder-shown {
  text-overflow: clip !important;
}
.card-hover-up {
  transition: 0.3s !important;
}
.card-hover-up:hover {
  transition: 0.3s !important;
  transform: scale(1.05) !important;
}
.clock {
  margin-left: 2em !important;
  color: white;
  margin: 50px 0;
  text-align: center;
  font-size: 150px;
}

#IdTracker.ant-input::placeholder {
  color: rgb(211, 211, 211) !important;
}
.get-quote-notice {
  position: absolute;
  margin-left: 0% !important;
}
.home-header-h1 {
  font-family: Autobus-Bold;
  margin-left: -28% !important;
}
.home-sub-header {
  top: 7em !important;
  margin-left: -19% !important;
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #ffffff;
  position: absolute;
  transform: rotate(-45deg);
  left: -2.5em;
  top: 22.3em;
  margin: -20px 0 0 -20px;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}
.pin::after {
  content: "";
  width: 14.5px;
  height: 14.5px;
  margin: 8px 0 0 8px;
  background: #2482db;
  position: absolute;
  border-radius: 50%;
}
.pulse {
  background: #ffffff;
  border-radius: 50%;
  z-index: 99 !important;
  height: 20px;
  width: 20px;
  position: absolute;
  left: -2.7em;
  top: 23em;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse::after {
  content: "";
  border-radius: 50%;
  height: 55px;
  width: 103px;
  position: absolute;
  margin: -13px 0 0 -40px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #ffffff;
  animation-delay: 1.1s;
}
@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
.pin1 {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #2482db;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;

  top: 37em;
  margin: -20px 0 0 -20px;
  animation-name: bounce1;
  animation-fill-mode: both;
  animation-duration: 1s;
}
.pin1::after {
  content: "";
  width: 14.5px;
  height: 14.5px;
  margin: 8px 0 0 -8px;
  background: #fdfdfd;
  position: absolute;
  border-radius: 50%;
}
.pulse1 {
  background: #2482db;
  border-radius: 50%;
  z-index: 99 !important;
  height: 15px;
  width: 15px;
  position: absolute;
  left: 50%;
  top: 37.5em;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse1::after {
  content: "";
  border-radius: 50%;
  height: 55px;
  width: 103px;
  position: absolute;
  margin: -13px 0 0 -50px;
  animation: pulsate1 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 0px 1.5px #2482db;
  animation-delay: 1.1s;
}
@keyframes pulsate1 {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
}
@keyframes bounce1 {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
.about-handshake {
  animation: pulsate12 13s ease-out;
  animation-iteration-count: infinite;

  animation-delay: 0s;
}
@keyframes pulsate12 {
  0% {
    transform: skewY(5deg);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: skewY(5deg);
  }
}
.about-delivery {
  animation: pulsate10 10s ease-out;
  animation-iteration-count: infinite;

  animation-delay: 1.1s;
}
@keyframes pulsate10 {
  0% {
    transform: skew(-0deg);
  }
  30% {
    transform: skew(-8deg);
  }
  80% {
    transform: skew(5deg);
  }
  100% {
    transform: skew(-0deg);
  }
}
.about-truck {
  animation: pulsate11 10s ease-out;
  animation-iteration-count: infinite;

  animation-delay: 1.1s;
}
@keyframes pulsate11 {
  0% {
    transform: scale(1.05);
  }
  50% {
    transform: skew(-3deg);
  }
  100% {
    transform: scale(1.05);
  }
}

.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
  border-radius: 0em !important;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #e0f0fd !important;
  color: #2482db;
  width: 20em;
  height: 4em;
  border: none !important;
}
.ant-descriptions-title {
  color: white !important;
  font-size: 17px !important;
  height: 2.7em;
  margin-top: 1em;
  padding-top: 0.55em;
  border-radius: 0.15em;
  background-color: #2482db;
}
.get-quote-pay-decription {
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #ffffff !important;
}
.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  max-width: 8em;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 0px solid #acacac !important;
}
.home-sub-header {
  text-align: right !important;
  position: absolute;
  font-size: 19px;
  columns: rgba(255, 255, 255, 0.445) !important;
  top: 3.5em !important;
  left: 15%;
  width: 84%;
}
.header-links {
  text-align: right;
  margin-top: -5em;
  font-size: 16px;
}
.get-quote-notice1 {
  text-align: center !important;
  margin: 2em !important;
  font-size: 16px;
}
.get-quote-card {
  font-size: 14.7px;
}
.ant-input {
  border-radius: 0.5px;
}
.header-links-quote {
  text-align: right;
  margin-top: -5em;

  font-size: 16px;
}
.get-started-line {
  position: absolute;
  left: -0.95em;
  top: 4.5em;
  width: 30%;
}
.get-started-button {
  left: 16.7em !important;
  top: -82.5em !important;
  margin-bottom: 1em !important;
  margin-right: 0em !important;
}

.get-started-button.ant-btn:hover,
.get-started-button.ant-btn:focus,
.get-started-button.ant-btn:active,
.get-started-button.ant-btn.active {
  border-radius: 0.3em;
  box-shadow: none;
  transition: 0.3s;
  padding: 1em !important;
  height: 3.5em;

  color: #ffffff;
  background: #0000000c;
}

a:hover {
  text-decoration: underline;
}
.courier-mockup {
  position: absolute;
  top: 4em !important;
  transform: translatex(800px);
  width: 50em;
  max-width: 48em;
  z-index: -1;
  animation: cardrive 8s forwards;
  animation-iteration-count: once;
}
@keyframes cardrive {
  100% {
    transform: translatex(-10px);
  }
}

.courier-wheel {
  position: absolute;
  top: 17.4em !important;

  transform: translatex(880px) rotate(150deg);
  z-index: -1;
  animation: carwheel 8s forwards;
  animation-iteration-count: once;
}
@keyframes carwheel {
  100% {
    transform: translatex(70px) rotate(-200deg);
  }
}

.ant-layout-header {
  background: #ffffff00;
}
.courier-wheel-2 {
  position: absolute;
  top: 17.4em !important;

  transform: translatex(1310px) rotate(150deg);
  z-index: -1;
  animation: carwheel2 8s forwards;
  animation-iteration-count: once;
}
@keyframes carwheel2 {
  100% {
    transform: translatex(505px) rotate(-200deg);
  }
}

.ant-layout-header {
  background: #ffffff00;
}

@media only screen and (max-width: 1470px) {
  .header-links {
    transform: scale(0.8);
    text-align: right;
    margin-left: 28%;
    margin-top: -5.5em;
  }
  .header-links-quote {
    transform: scale(0.8);
    text-align: right;
    margin-left: 28%;
    margin-top: -5.5em;
  }
}

@media only screen and (max-width: 1000px) {
  .tracker-line {
    margin-top: 2em !important;
  }
  /* Position and sizing of burger button */
  .bm-burger-button {
    visibility: visible !important;
    position: fixed;
    width: 32px;
    height: 25px;
    right: 15px;
    top: 40px;
  }
  .bm-burger-bars-hover {
    opacity: 1 !important;
    background: #ffffff !important;
  }
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    visibility: visible;
    border-radius: 0.5em;
    background: #fffffff8;
  }
  .bm-burger-bars:hover:active {
    visibility: visible;
    border-radius: 0.5em;
    background: #fffffff8 !important;
  }
  .my-class {
    visibility: visible;
    border-radius: 0.5em !important;
    background: #2f87cf !important;
  }
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    visibility: visible;
    background: #a90000;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    visibility: visible;
    height: 24px;
    width: 24px;
    transform: scale(2);
    top: 2.7em !important;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    visibility: visible;
    background: #176ca5;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    visibility: visible;
    text-align: center !important;
    height: 100vh !important;
    width: 100vw !important;
    position: fixed;
    top: 0 !important;
  }

  /* General sidebar styles */
  .bm-menu {
    visibility: visible;
    padding-top: 15em !important;
    background: #ffffff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    visibility: visible;
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    visibility: visible;
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    margin-top: -0.8em;
    visibility: visible !important;
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    visibility: visible !important;
    background: rgba(0, 0, 0, 0.3);
  }
  .tracker-line-track-trace {
  }
  .contact-back {
    height: 71em !important;
  }
  /* .rates-card{ */
  /* margin: auto !important; */
  /* width: 60vw !important; */
  /* } */
  .rates-col {
    text-align: center !important;
    margin: auto !important;
    width: 100% !important;
  }
  .wave-img-big {
    margin-top: 15em !important;
  }
  .card-hover-up {
    transition: 5s !important;
    top: -9em !important;
    left: 10% !important;
  }
  .card-hover-up:hover {
    transform: none !important;
  }

  .big-wave {
    position: absolute !important;
    bottom: -16em !important;
  }

  .how-it-header {
    margin: auto !important;
    left: 40%;
    margin-top: -7em !important;
    color: black !important;
  }
  .contact-col-1 {
    text-align: center !important;
    top: -8em !important;
    left: -8em !important;
    min-width: 29.5em;
    width: 137%;
  }
  .contact-col-2 {
    display: none;
  }
  .contact-text {
    text-align: center !important;
  }
  .courier-mockup {
    top: 9em !important;
  }
  .courier-wheel {
    top: 22.4em !important;
  }
  .courier-wheel-2 {
    top: 22.4em !important;
  }
  .header-links {
    display: none;
  }
  .header-links-quote {
    display: none;
  }
  .home-header-h1 {
    text-align: center !important;
    font-size: 34px;
    margin: auto !important;
    width: 120% !important;
    margin-top: -3em !important;
    margin-left: 25% !important;
  }
  .home-sub-paragraph {
    font-size: 20px !important;
    text-align: center !important;
    margin: auto !important;
    width: 120% !important;
    margin-left: 25% !important;
  }
  .get-started-line {
    text-align: center !important;
    margin: auto !important;
    margin-top: -2em !important;
    margin-left: 67% !important;
  }
  .pin {
    visibility: hidden;
  }

  .pulse {
    visibility: hidden;
  }

  .what-we-col-pin {
    width: 3.3em !important;
    margin-top: 8em !important;
    margin-left: 43% !important;
  }
  .how-we-col-pin {
    width: 3.3em !important;
    margin-top: 13.5em !important;
    margin-left: 30% !important;
  }

  .our-future-col-pin {
    width: 3.3em !important;
    margin-top: 5.8em !important;
    margin-left: 63% !important;
  }
  .what-we-col {
    white-space: nowrap;
    margin: auto !important;
    margin-top: -3em !important;
    margin-left: 0% !important;
  }
  .what-we-col-img {
    opacity: 0.6;
    width: 18em !important;
    position: absolute;
    margin: auto !important;
    margin-top: 5em !important;
    margin-left: 46% !important;
  }
  .how-we-col {
    white-space: nowrap;
    margin: auto !important;
    margin-top: 5em !important;
    margin-left: 4% !important;
  }
  .how-we-col-img {
    opacity: 0.6;
    width: 18em !important;
    position: absolute;
    margin: auto !important;
    margin-top: 5em !important;
    margin-left: 35% !important;
  }
  .our-future-col {
    white-space: nowrap;
    margin: auto !important;
    margin-top: -4em !important;
    margin-left: 11% !important;
    margin-bottom: 8em !important;
  }
  .our-future-col-img {
    opacity: 0.6;
    width: 18em !important;
    position: absolute;
    margin: auto !important;
    margin-top: -3em !important;
    margin-left: 55% !important;
  }
  .get-quote-notice {
    width: 90vw;
    position: relative;
    margin-left: 0% !important;
  }
}
@media only screen and (max-width: 740px) {
  .tracker-line-track-trace {
    margin-top: -5em !important;
  }
  .wave-img-big {
    margin-top: 75em !important;
  }
  .about-header-transform {
    text-align: center !important;
    margin-left: -1.3em !important;
  }
  .about-paragraph-transform {
    color: rgb(0, 0, 0) !important;
    transform: scale(0.9);
    font-size: 15px !important;
    text-align: center !important;

    margin-left: -1.3em !important;
  }

  .home-header-h1 {
    font-size: 38px;
    font-weight: 700;
    text-align: center !important;
  }

  .home-sub-paragraph {
    font-size: 17px !important;
    font-weight: 500 !important;
    text-align: center !important;

    color: rgba(255, 255, 255, 0.918) !important;
  }
  .get-started-line {
    margin: auto;
    margin-top: 0.7em !important;
  }
  .get-started-button {
    background-color: rgba(41, 41, 41, 0.589) !important;
    color: rgb(255, 255, 255) !important;
  }
  .courier-mockup {
    top: 9em !important;
    animation: cardrive1 8s forwards;
    animation-iteration-count: once;
  }
  @keyframes cardrive1 {
    100% {
      transform: translatex(-1250px);
    }
  }
  .courier-wheel {
    top: 22.4em !important;
    animation: carwheel3 8s forwards;
  }
  @keyframes carwheel3 {
    100% {
      transform: translatex(-1170px) rotate(-200deg);
    }
  }
  .courier-wheel-2 {
    top: 22.4em !important;

    transform: translatex(1310px) rotate(150deg);

    animation: carwheel4 8s forwards;
  }
  @keyframes carwheel4 {
    100% {
      transform: translatex(-735px) rotate(-200deg);
    }
  }
  .home-logo {
    margin-top: 0em !important;
    margin-left: -4em !important;
    transform: scale(0.8);
  }
  .pin {
    width: 30px;
    left: -1.8em;
    position: absolute !important;
    margin-top: -5.5em !important;
    margin-left: 100% !important;
    visibility: visible;
  }

  .pulse {
    transform: scale(0.8);
    height: 13px;
    width: 30px;
    left: -1.8em;
    margin-top: -3em !important;
    margin-left: 100% !important;
    visibility: visible;
  }
  .pulse::after {
    left: 0.3em;
    margin-top: -1.3em !important;
  }
}
@media only screen and (max-width: 580px) {
  .number-1 {
    margin-left: -1em;
  }
  .get-quote-header {
    width: 120vw;
    margin-left: -2.5em;
  }
  .get-quote-header-1 {
  }
  .calculate-button-1 {
    width: 70% !important;
    margin-left: -0.3em !important;
  }
  .circle-1 {
    transform: scale(0.6);
    left: 28% !important;
    top: -2em !important;
  }
  .circle-2 {
    transform: scale(0.6);
    left: 22% !important;
    top: -2em !important;
  }
  .circle-3 {
    transform: scale(0.6);
    left: 22% !important;
    top: -2em !important;
  }
  .calculate-button {
    width: 100% !important;
  }
  .select-delivery-collect {
    left: -11.5% !important;
    width: 108vw !important;
  }
  .select-delivery-collect-p {
    margin-left: -3.3em !important;
    width: 100vw !important;
  }
  .footer-watermark-quote {
    font-size: 13px !important;
    margin-top: 0em !important;
  }
  .footer-watermark {
    font-size: 13px !important;
    margin-top: 0em !important;
  }
  .social-paragraph {
    font-size: 14px !important;
  }
  .community-pic {
    margin-left: -10em !important;
    transform: scale(0.8);
  }
  .blue-back {
    visibility: hidden;
  }

  .what-we-col-pin {
    width: 3.3em !important;
    margin-top: -0.6em !important;
    margin-left: 28.5% !important;
  }
  .how-we-col-pin {
    visibility: hidden;
  }

  .our-future-col-pin {
    width: 3.3em !important;
    margin-top: 0em !important;
    margin-left: 48% !important;
  }

  .what-we-col {
    white-space: normal !important;

    margin: auto !important;

    margin-top: -4em !important;
    margin-left: -19% !important;
  }
  .what-we-col-img {
    width: 25em !important;
    opacity: 0.3;
    margin-left: 28% !important;
    margin-top: -7em !important;
  }
  .how-we-col {
    white-space: normal !important;
    margin: auto !important;

    margin-top: 7.5em !important;
    margin-left: -10.5% !important;
  }
  .how-we-col-img {
    width: 21em !important;
    transform: rotateY(180deg);
    opacity: 0.4;
    margin-left: -150% !important;
    margin-top: 3em !important;
  }
  .our-future-col {
    white-space: normal !important;
    margin: auto !important;
    margin-top: -17.4em !important;
    margin-left: 1.5em !important;
    margin-bottom: 8em !important;
  }
  .our-future-col-img {
    width: 20em !important;
    opacity: 0.4;
    margin-left: 38% !important;
    margin-top: -10em !important;
  }
}
@media only screen and (max-width: 500px) {
  .dimensions-col {
    text-align: center !important;
    margin-left: -48% !important;
    width: 173vw;
  }
}
@media only screen and (max-width: 550px) {
  .ant-descriptions-title {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    width: 110vw;
    margin-left: -2.5em;
  }
  .get-quote-pay-decription {
    width: 180vw !important;
  }
  /* .rates-card {
    margin: auto !important;
    width: 88vw !important;
  } */
  .rates-col {
    text-align: center !important;
    margin: auto !important;
    width: 60% !important;
  }
  .get-quote-card {
    min-width: 1em !important;
    width: 100vw !important;
    margin-left: em !important;
  }
  .input-col {
    width: 48vw !important;
  }
  .quick-pic {
    margin-left: 5em !important;
    transform: scale(0.8);
  }
  .service-pic {
    left: 45% !important;
    transform: scale(0.8);
  }
  .shipping-pic {
    left: 76.5% !important;
    transform: scale(0.8);
  }

  .pay-pic {
    left: 102% !important;
    transform: scale(0.8);
  }
  .quote-steps {
  }
  .tracker-line {
    margin-left: -18% !important;
    margin-top: -0em !important;
  }
  .tracker-line-track-trace {
    margin-left: -7% !important;
    margin-top: -7em !important;
  }
  .ant-steps-dot .ant-steps-item-tail,
  .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    top: 2px;
    width: 100% !important;
    padding: 0;
  }
  .ant-steps-dot .ant-steps-item-content,
  .ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 100px !important;
  }
  .ant-steps-item-title {
    position: relative;
    display: inline-block;
    padding-right: 16px;
    width: 5em;
    color: rgba(0, 0, 0, 0.65);
    margin-left: 3em;
    font-size: 13px;
    line-height: 32px;
  }
  .ant-steps-label-vertical .ant-steps-item-content {
    display: block;

    margin-top: 8px;
  }
  .ant-steps-dot .ant-steps-item-tail,
  .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    top: 2px;
    width: 120%;
    margin: 0 0 0 70px;
    padding: 0;
  }
}

@media only screen and (max-width: 450px) {
  .person-pic {
    margin-top: 5em !important;

    right: -10% !important;
    transform: scale(0.7);
  }
}
@media only screen and (max-width: 770px) {
  /* .rates-card {
    top: 0em !important;
    margin: auto !important;
    margin-top: 1em !important;
    width: 88vw !important;
  } */
  .back-button {
    margin-left: -3em !important;
    width: 3em !important;
    top: 2.6em !important;
    z-index: 99;
    margin-left: -80em !important;
  }
  .parcel-details-card {
    border-radius: 0.8em !important;
    margin-top: 3em !important;
    height: 21em !important;
  }
  .waybill-error-text {
    margin-top: 10em !important;
  }
}
@media only screen and (max-width: 550px) {
  .person-pic {
    margin-top: 4.5em !important;
    transform: scale(0.7);
    margin-right: -2% !important;
  }
  .parcel-details-card {
    margin-top: -5em !important;
    margin-left: 2.1em;
  }
}
@media only screen and (max-width: 1350px) and (min-width: 1000px) {
  .home-content {
    left: 7em !important;
  }
}
